import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Container, Form } from "react-bootstrap";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { useAppDispatch } from "../../../../store/hooks";
import GridClientComponent from "../../../../components/grid/gridClientSide";
import { GetReports, postReports } from "../../../../store/api/apiSlice";
import GlobalPopup from "../../../shared/GlobalPopup/globalPopup";
import { toast } from "react-toastify";

function ReportManagament() {
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const [gridApi, setGridApi] = useState<any>(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [show, setShow]: any = useState();

  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    isForExcel: false,
    sortField: "",
    csvName: "",
    URL: "",
    filterOptions: [],
  });
  let finalData: any = [];
  const dispatch = useAppDispatch();
  const columnitems: any = [
    {
      headerName: "Report Name",
      field: "name",
      hide: false,
      cellStyle: { cursor: "pointer" },
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      flex: 1,
    },
    {
      headerName: "Report Type",
      field: "type",
      hide: false,
      cellStyle: { cursor: "pointer" },
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      flex: 1,
    },
    {
      headerName: "Email Report",
      field: "isSubscribed",
      hide: false,
      comparator: customComparator,
      cellStyle: { cursor: "pointer", textAlign: "center" },
      cellClass: "email-report-checked",
      suppressMenu: true,
      sortable: false,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 1,
      cellRenderer: function (params: any) {
        if (params.value === true && !finalData.includes(params.data.id)) {
          finalData.push(params.data.id);
        }
        setSelectedIds(finalData);
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Form.Check
              className="ag-header-cell-comp-wrapper"
              checked={params.data.type === "Monthly Report" ? false : params.value}
              value={params.data.id}
              onChange={(e) => OnCheckBoxCheck(e)}
              disabled={params.data.type === "Monthly Report" ? true : false}
            ></Form.Check>
          </div>
        );
      },
    },
  ];

  const OnCheckBoxCheck = (e: any) => {
    const { value, checked } = e.target;
    if (checked === true) {
      finalData.push(value);
    } else {
      finalData = finalData.filter(function (item: any) {
        return item !== value;
      });
    }
    setSelectedIds(finalData);
    var gridRows: any = gridRef.current?.props?.rowData;
    var rows: any = [];
    for (let index = 0; index < gridRows.length; index++) {
      const row: any = gridRows[index];
      if (row.id === value) {
        row.isSubscribed = checked;
        rows.push(row);
      } else {
        rows.push(row);
      }
    }
    updateGridData("rows", rows);
  };

  const SaveButtonClick = () => {
    setShow(true);
  };
  function customComparator() {
    return 0; //means no comparing and no sorting client side
  }
  const [colDefs, setColDefs] = useState(columnitems);
  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const reportManagamentApi = async () => {
    try {
      if (gridApi) {
        dispatch(GetReports()).then((result: any) => {
          updateGridData("rows", result.payload.data);
          updateGridData("totalRows", result?.payload?.data.length);
        });
      }
    } catch (error) { }
  };

  useEffect(() => {
    reportManagamentApi();
  }, [gridApi]);

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };

  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };

  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  };

  const handleSaveReport = () => {
    let values: any = {
      reportId: selectedIds,
    };
    dispatch(postReports(values)).then((result: any) => {
      if (result?.payload?.isSuccess === true) {
        toast.success("Saved Successfully");
        setShow(false);
        reportManagamentApi();
      }
    });
  };
  return (
    <Container fluid>
      <PageTitle
        name="Reports Management"
        isNormal={true}
        subname="Reports will be dispatched via email with attachments on the 10th day of each month for the selected reports."
      ></PageTitle>
      <div className="content_body report-mgmt-grid">
        <GridClientComponent
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          globalSearch={globalSearch}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
          hideheader={false}
          hidesearch={true}
          gridfilterbutton={false}
          autoSizeStrategy={{
            type: "fitCellContents",
            colIds: [],
          }}
        ></GridClientComponent>
        <GlobalPopup
          show={show}
          showImage={false}
          handleClose={() => setShow(false)}
          handleSave={handleSaveReport}
          message="Are you sure you want to update your report deliver preferences?"
        />
        <div className="d-flex justify-content-end mt-2">
          <Button variant="primary" onClick={SaveButtonClick}>
            Save Preference
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              reportManagamentApi();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default ReportManagament;
