import axios from "axios";
import getToken from "../store/api/api";
import { toast } from "react-toastify";
import { defaultErrorMessage, ignoreFrontMessageCodes } from "../interface/comman.constant";
import { msalInstance } from "..";

const API_URL = process.env.REACT_APP_BASE_URL_API;
const REPORT_URL = process.env.REACT_APP_BASE_REPORT;
const USER_URL = process.env.REACT_APP_BASE_USER;
const INVENTORY_URL = process.env.REACT_APP_INVENTORY_URL;
const NOTIFICATION_URL = process.env.REACT_APP_NOTIFICATION_URL;
const NOTIFICATION_SEND_URL = process.env.REACT_APP_SEND_NOTIFICATION_URL;

axios.interceptors.request.use(
  async (config) => {
    const accessToken = await getToken();
    const userJwtToken = accessToken;

    if (config.headers && accessToken) {
      config.headers.Authorization = "Bearer " + accessToken;
      const azureB2C = localStorage.getItem('AzureB2CImpersonate');

      if (azureB2C)
        config.headers.UserImpersonated = azureB2C;
    }

    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error) {
      const originalConfig = error.config;

      if (error.response) {
        if (originalConfig.url !== "/auth/signin") {
          // Access Token was expired
          if (
            error.response.status === 405 &&
            error.response.data.key === "PERMISSION_DENIED"
          ) {
            try {
              // localStorage.clear();
              window.location.href = "/access-denied";
            } catch (_error) {
              return Promise.reject(_error);
            }
          }

          if (
            error.response.status === 417 && window.location.pathname !== "/technical-failure"
          ) {
            try {
              window.location.href = "/technical-failure";
            } catch (_error) {
              return Promise.reject(_error);
            }
          }

          if (error.response.status === 403 || error.response.status === 401) {
            try {
              localStorage.clear();
              msalInstance.logoutRedirect();
              window.location.href = "/";
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
        }

        if (!(ignoreFrontMessageCodes).includes(error.response.status)) {
          toast.error(defaultErrorMessage);
        }
      }
      return Promise.reject(error);
    }
    return Promise.resolve();
  }
);

const axiosExport = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  options: axios.options,
  Api: API_URL,
  ReportAPI: REPORT_URL,
  UserManageAPI: USER_URL,
  InventoryAPI: INVENTORY_URL,
  NotificationSendApi: NOTIFICATION_SEND_URL,
  NotificationApi: NOTIFICATION_URL
};

export default axiosExport;
