import React from "react";
import { Col, Row } from "react-bootstrap";

function NDCDetails(props: any) {
  return (
    <div>
      <Col xs={12}>
        <div className="form_preview_box">
          <Row className="gx-5">
            <Col xs={12} md="auto">
              <div className="form_preivew_label">Product</div>
              <div className="form_preivew_text">
                {props?.ndcDetailsData?.product}
              </div>
            </Col>
            <Col xs={12} md="auto">
              <div className="form_preivew_label">Case & Package Size</div>
              <div className="form_preivew_text">
                {props?.ndcDetailsData?.caseAndPackageSize}
              </div>
            </Col>
            <Col xs={12} md="auto">
              <div className="form_preivew_label">Quantity Validation</div>
              <div className="form_preivew_text">
                <div className={props.quantityData > 50 ? "pending" : ""}>
                  {props?.quantity && props?.quantityData
                    ? props?.quantityData +
                      " " +
                      props.ndcDetailsData?.dosageForm
                    : ""}
                  {!props?.quantity && props?.quantityData
                    ? props?.quantityData +
                      " " +
                      props.ndcDetailsData?.packageDescription
                    : ""}
                </div>
              </div>
            </Col>
            <Col xs={12} md="auto">
              <div className="form_preivew_label">Same Inner/Outer NDC</div>
              <div className="form_preivew_text">
                {props?.ndcDetailsData?.isInnerNdc ? (
                  <i className="fa-solid fa-circle-info"></i>
                ) : (
                  <>-</>
                )}
              </div>
            </Col>
            <Col xs={12} md="auto">
              <div className="form_preivew_label">DEA Class</div>
              <div className="form_preivew_text">
                {props?.ndcDetailsData?.deaClass}
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </div>
  );
}

export default NDCDetails;
