import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { useAppDispatch } from "../../../../store/hooks";
import {
  checkCustomerLicence,
  getDisposalAccount,
  getKitSummaryList,
  postRequestKit,
} from "../../../../store/api/apiSlice";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalPopup from "../../../shared/GlobalPopup/globalPopup";
import moment from "moment";
import {
  formatDatePipe,
  formateIds,
  gridAlignment,
} from "../../../../interface/comman.constant";
import { toast } from "react-toastify";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getTableFields } from "../../../../helpers/getTableFields";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import { usePermission } from "../../../../helpers/hooks/usePermissions";
import NewNdcSuccessModal from "../startReturn/newNdcSuccessModal";

function RequestAPharmaWasteKit() {
  const [showProductTour, setShowProductTour] = useState(false);
  const location = useLocation();
  const journeySteps = [
    {
      target: ".request-one",
      content:
        "This page offers Pharma Waste/Take Back Kits or Box and Ship service that you can order to help you get rid of all of your extra supplies. ",
      placement: "center" as const,
    },
    {
      target: ".request-two",
      content:
        "To order kits for non-controlled returns, PharmaWaste, or Drug Take Back, select the request type, associated account, and number of kits needed. Then hit Submit. Kits will be received within 3-5 business days.",
    },
    ...location.pathname === "/services/request-supplies" ? [{
      target: ".request-three",
      content:
        "Select kits if you require a box and liner or labels only if you only require a shipping label.",
    }] : [],
  ];
  let navigate = useNavigate();
  const permissions = usePermission(true);
  const [errorFromMessage, setErrorFromMessage] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("kits");
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const formRef = useRef<any>();
  const [gridApi, setGridApi] = useState<any>(null);
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    sortField: "",
    startDate: "",
    endDate: "",
  });
  const [requestTypeChanged, setRequestTypeChanged] = useState("");
  const [SuccessShow, setSuccessShow] = useState(false);
  const columnitems = [
    {
      field: "companyName",
      headerName: "Account",
      tooltipField: "companyName",
      headerTooltip: "Account",
      tooltipShowDelay: 0,
      hide: false,
      comparator: customComparator,
      flex: 1,
      valueFormatter: function (params: any) {
        return params.data.disposalAccountName;
      },
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      cellStyle: { cursor: "pointer" },
    },
    {
      field: "quantity",
      headerName: "Number of Kits",
      hide: false,
      comparator: customComparator,
      headerTooltip: "NUMBER OF KITS",
      tooltipShowDelay: 0,
      flex: 1,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      cellStyle: { cursor: "pointer" },
      type: gridAlignment.RIGHT,
    },
    {
      field: "createdOn",
      headerName: "Date",
      headerTooltip: "DATE",
      tooltipShowDelay: 0,
      flex: 1,
      valueFormatter: function (params: any) {
        return params.value &&
          params.value !== "" &&
          params.value !== null &&
          params.value !== undefined
          ? moment.utc(params.value).local().format("MM/DD/YYYY")
          : "";
      },
      hide: false,
      comparator: customComparator,
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
    },
    {
      field: "createdByUserName",
      headerName: "CREATED BY",
      tooltipField: "createdBy",
      headerTooltip: "CREATED BY",
      tooltipShowDelay: 0,
      hide: false,
      comparator: customComparator,
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      field: "status",
      headerTooltip: "STATUS",
      headerName: "Status",
      tooltipShowDelay: 0,
      hide: false,
      comparator: customComparator,
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      cellRenderer: function (params: any) {
        let tmpClass: any = "";
        if (params.data.status === "Submitted") {
          tmpClass = "pending";
        } else {
          tmpClass = "created";
        }
        return (
          <div className="status">
            <div className={tmpClass}>{params.data.status}</div>
          </div>
        );
      },
    },
  ];
  const columnitemsSupply = [
    {
      field: "companyName",
      headerName: "Account",
      tooltipField: "companyName",
      headerTooltip: "Account",
      tooltipShowDelay: 0,
      hide: false,
      comparator: customComparator,
      flex: 1,
      valueFormatter: function (params: any) {
        return params.data.disposalAccountName;
      },
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      cellStyle: { cursor: "pointer" },
    },
    {
      field: "quantity",
      headerName: "NUMBER OF KITS",
      headerTooltip: "NUMBER OF KITS",
      tooltipShowDelay: 0,
      hide: false,
      comparator: customComparator,
      flex: 1,
      valueFormatter: function (params: any) {
        if (params.data.supplyTypeId === 2) return "";
        else return params.value;
      },
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      cellStyle: { cursor: "pointer" },
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "NUMBER OF LABELS",
      field: "quantity",
      headerTooltip: "NUMBER OF LABELS",
      tooltipShowDelay: 0,
      hide: false,
      comparator: customComparator,
      flex: 1,
      valueFormatter: function (params: any) {
        if (params.data.supplyTypeId === 2) return params.value;
        else return "";
      },
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      cellStyle: { cursor: "pointer" },
      type: gridAlignment.RIGHT,
    },
    {
      field: "createdOn",
      headerName: "Date",
      headerTooltip: "DATE",
      tooltipShowDelay: 0,
      flex: 1,
      valueFormatter: function (params: any) {
        return formatDatePipe(params.value);
      },
      hide: false,
      comparator: customComparator,
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
    },
    {
      field: "createdByUserName",
      headerName: "CREATED BY",
      headerTooltip: "CREATED BY",
      tooltipShowDelay: 0,
      hide: false,
      suppressMenu: true,
      comparator: customComparator,
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      field: "status",
      headerTooltip: "STATUS",
      headerName: "Status",
      tooltipShowDelay: 0,
      hide: false,
      comparator: customComparator,
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      cellRenderer: function (params: any) {
        let tmpClass: any = "";
        if (params.data.status === "Submitted") {
          tmpClass = "pending";
        } else {
          tmpClass = "created";
        }
        return (
          <div className="status">
            <div className={tmpClass}>{params.data.status}</div>
          </div>
        );
      },
    },
  ];

  const [colDefs, setColDefs]: any = useState([]);
  const [validated, setValidated] = useState(false);
  const [kitsValue, setKitsValue]: any = useState();
  const [disposalAccount, setDisposalAccount] = useState();
  const [disposalAccountName, setDisposalAccountName] = useState("");
  const [disposalAccountData, setDisposalAccountData] = useState([]);
  const [show, setShow] = useState(false);
  const [errorDisposalAccountMessage, setErrorDisposalAccountMessage] =
    useState("");
  const [errorQuantityMessage, setErrorQuantityMessage] = useState("");
  const kit = [
    ...(permissions?.["RequestBoxAndShipSupplies"]
      ? [
        {
          id: "3",
          value: "Box & Ship Supplies",
        },
      ]
      : []),
    ...(permissions?.["RequestAPharmawasteKit"]
      ? [
        {
          id: "1",
          value: "PharmaWaste Kit",
        },
      ]
      : []),
    ...(permissions?.["RequestATakebackKit"]
      ? [
        {
          id: "2",
          value: "Take Back kit",
        },
      ]
      : []),
  ];
  const options: any = [];

  kit &&
    kit.map((item: any) =>
      options.push({
        value: item.id,
        label: item.value,
      })
    );

  const disposal_options: any = [];

  disposalAccountData &&
    disposalAccountData.map((item: any) =>
      disposal_options.push({
        value: item.id,
        label: item.customerCode + "-" + item.disposalAccountName,
      })
    );
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function customComparator() {
    return 0; //means no comparing and no sorting client side
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const handleReset = () => {
    formRef?.current.reset();
    setValidated(false);
  };

  const requestType = () => {
    if (location.pathname === "/services/request-supplies") {
      return 2;
    } else if (location.pathname === "/services/request-pharma-kit") {
      return 0;
    } else {
      return 1;
    }
  };

  const getKitSummary = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              RequestKitType: requestType(),
              PageSize: gridData.pageSize,
              pageNumber: gridApi.paginationGetCurrentPage() === 0 ? 1 : gridApi.paginationGetCurrentPage() + 1,
              GlobalSearch: gridData.globalSearch,
              StartDate: gridData.startDate
                ? moment
                  .utc(gridData.startDate, "MM/DD/YYYY")
                  .format("YYYY-MM-DD")
                : null,
              EndDate: gridData.endDate
                ? moment
                  .utc(gridData.endDate, "MM/DD/YYYY")
                  .format("YYYY-MM-DD")
                : null,
              SortField: gridData.sortField,
              SortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              CustomerIds: formateIds(userAccounts),
              filterOptions: getTableFields(params.request.filterModel),
            };
            dispatch(getKitSummaryList(data)).then((result: any) => {
              if (result?.payload?.data?.requestKits?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.requestKits,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData("rows", result?.payload?.data?.requestKits);
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };
  const getDropDownData = () => {
    try {
      let data;
      if (location.pathname === "/services/request-pharma-kit") {
        data = 3;
      } else if (location.pathname === "/services/request-take-back-kit") {
        data = 4;
      } else {
        data = 5;
      }
      localStorage.removeItem("product_tour");
      dispatch(
        getDisposalAccount({
          data: data,
          customerIds: formateIds(userAccounts),
        })
      ).then((result: any) => {
        setDisposalAccountData(result?.payload?.data);
      });
    } catch (error) { }
  };
  useEffect(() => {
    const productTour = localStorage.getItem("product_tour");
    if (productTour === "true") {
      setShowProductTour(true);
    }
    getDropDownData();
  }, [location.pathname, refreshPage]);

  useEffect(() => {
    if (location.pathname === "/services/request-supplies") {
      setColDefs(columnitemsSupply);
    } else {
      setColDefs(columnitems);
    }
  }, [requestTypeChanged]);

  useEffect(() => {
    getKitSummary();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.globalSearch,
    location.pathname,
    gridData.startDate,
    gridData.endDate,
    gridData.sortOrder,
    gridData.sortField,
    gridApi,
    refreshPage,
  ]);

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };
  const requestTypeChange = (e: any) => {
    formRef.current.reset();
    if (e.value === "1") {
      navigate("/services/request-pharma-kit");
      setDisposalAccount(undefined);
      setErrorDisposalAccountMessage("");
      setErrorQuantityMessage("");
      setValidated(false);
    } else if (e.value === "2") {
      navigate("/services/request-take-back-kit");
      setDisposalAccount(undefined);
      setErrorDisposalAccountMessage("");
      setErrorQuantityMessage("");
      setValidated(false);
    } else {
      navigate("/services/request-supplies");
      setDisposalAccount(undefined);
      setErrorDisposalAccountMessage("");
      setErrorQuantityMessage("");
      setValidated(false);
    }
    setRequestTypeChanged(e.value);
  };

  const disposalAccountChange = (e: any) => {
    setErrorDisposalAccountMessage("");
    disposalAccountData?.map((s: any) => {
      if (s.id === e.value) {
        return setDisposalAccountName(
          s.customerCode + "-" + s.disposalAccountName
        );
      }
    });
    setDisposalAccount(e.value);
    try {
      dispatch(checkCustomerLicence(e.value)).then((result: any) => {
        if (
          location.pathname === "/services/request-pharma-kit" &&
          !result.payload.data.requestPharmaWasteKitAccess
        ) {
          setErrorDisposalAccountMessage(
            "Request PharmaWaste Kit is not accessible for the selected Account"
          );
        } else if (
          location.pathname === "/services/request-take-back-kit" &&
          !result.payload.data.requestTakeBackKitAccess
        ) {
          setErrorDisposalAccountMessage(
            "Request Takeback Kit is not accessible for the selected Account"
          );
        } else if (
          location.pathname === "/services/request-supplies" &&
          !result.payload.data.returnOrderAccess
        ) {
          setErrorDisposalAccountMessage(
            "Request Box and Ship Supplies is not accessible for the selected Account"
          );
        }
      });
    } catch (error) { }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (disposalAccount === undefined) {
      setErrorDisposalAccountMessage("Please Select Account");
    }

    if (kitsValue === undefined) {
      setErrorQuantityMessage("Please Select Quantity");
    }
    if (location.pathname === "/services/request-supplies" && kitsValue > 10) {
      setErrorQuantityMessage("Quantity Value should not be greater than 10");
      return false;
    }
    if (errorDisposalAccountMessage !== "") {
      return false;
    }
    if (kitsValue?.includes(".")) {
      setErrorFromMessage("Invalid value");
    }
    if (
      location.pathname === "/services/request-supplies" &&
      kitsValue?.includes(".")
    ) {
      setErrorQuantityMessage("Invalid value");
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (errorFromMessage === "" && errorDisposalAccountMessage === "") {
        handleShow();
      }
    }
    if (disposalAccount && kitsValue) {
      setValidated(false);
    } else {
      setValidated(true);
    }
  };

  const numberOfKitsChange = (e: any) => {
    const inputValue = e.target.value;
    if (Number(inputValue) < 1) {
      setErrorFromMessage("You can not request less then 1 kit per request");
      return;
    } else {
      setErrorFromMessage("");
    }
    if (Number(inputValue) > 100) {
      setErrorFromMessage("You can request maximum 100 kits per request");
      return;
    } else {
      setErrorFromMessage("");
    }
    if (!isNaN(inputValue)) {
      setKitsValue(inputValue);
    }
    if (disposalAccount && inputValue) {
      setValidated(false);
    }
  };

  const handleSave = () => {
    try {
      let requestData = {
        requestKitTypeId:
          location.pathname === "/services/request-pharma-kit" ? 0 : 1,
        accountId: Number(disposalAccount),
        quantity: Number(kitsValue),
        accountName: disposalAccountName,
        supplyTypeId: 1,
      };
      let requestSupplyData = {
        requestKitTypeId: 2,
        accountId: Number(disposalAccount),
        accountName: disposalAccountName,
        quantity: Number(kitsValue),
        supplyTypeId: selectedRadio === "kits" ? 1 : 2,
      };
      if (Number(kitsValue) < 1) {
        setErrorFromMessage("You can not request less then 1 kit per request");
        return;
      } else if (Number(kitsValue) > 100) {
        setErrorFromMessage("You can request maximum 100 kits per request");
        return;
      }

      handleClose();

      if (
        errorFromMessage === "" &&
        errorQuantityMessage === "" &&
        errorDisposalAccountMessage === ""
      ) {
        dispatch(
          postRequestKit(
            location.pathname === "/services/request-supplies"
              ? requestSupplyData
              : requestData
          )
        ).then((result: any) => {
          if (result?.payload?.isSuccess) {
            // toast.success("Saved Successfully");
            setSuccessShow(true);
            getKitSummary();
            handleReset();
          } else {
            toast.error("Some error occurred while saving data.");
          }
        });
      }
    } catch (error) { }
  };

  const onKitFilterChange = (e: any) => {
    const monthsAgo = parseInt(e.value) || 12;
    let d = new Date();
    let end = d.toLocaleDateString();
    d.setMonth(d.getMonth() - monthsAgo);
    let start = d.toLocaleDateString();

    updateGridData("startDate", start);
    updateGridData("endDate", end);
  };

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      let { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      if (colId === "quantity_1") {
        colId = "quantity";
      }
      updateGridData("sortField", colId);
    }
  };

  const quantityChange = (e: any) => {
    let inputValue = e.target.value;
    if (e.target.value > 10) {
      setErrorQuantityMessage("Quantity Value should not be greater than 10");
    } else {
      setErrorQuantityMessage("");
    }
    if (!isNaN(inputValue)) {
      setKitsValue(inputValue);
    }
    if (disposalAccount && inputValue) {
      setValidated(false);
    }
  };

  const radioChange = (e: any, name: any) => {
    setSelectedRadio(name);
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const LabelsOrKit = () => {
    if (
      location.pathname === "/services/request-supplies" &&
      selectedRadio === "Labels"
    ) {
      return " Labels in ";
    } else {
      return " kits in ";
    }
  };
  const handleJoyrideCallback = (data: CallBackProps) => {
    if (data.action === "close" || data.action === "skip") {
      setShowProductTour(false);
      localStorage.removeItem("product_tour");
    }
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  };
  const SucessClose = () => {
    setSuccessShow(false);
  };
  return (
    <Container fluid>
      <Joyride
        run={showProductTour}
        steps={journeySteps}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
      />
      {show ? (
        <GlobalPopup
          show={show}
          showImage={true}
          handleClose={handleClose}
          handleSave={handleSave}
          message={
            "Are you sure you want to add " +
            kitsValue +
            LabelsOrKit() +
            // disposalAccount +
            // "-" +
            disposalAccountName +
            "?"
          }
        ></GlobalPopup>
      ) : (
        <></>
      )}

      {location.pathname === "/services/request-supplies" ? (
        <PageTitle name={"Request Supplies"}></PageTitle>
      ) : (
        <PageTitle
          name={
            location.pathname === "/services/request-pharma-kit"
              ? "Request a PharmaWaste kit "
              : "Request a TakeBack kit "
          }
        ></PageTitle>
      )}
      <div className={`content_body ${showProductTour ? "request-one" : ""}`}>
        <div className="top-controls">
          <Form
            ref={formRef}
            id="form"
            className="form_box"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            <Row className={`gx-3 ${showProductTour ? "request-two" : ""}`}>
              <Col xs={12} md={6} xl={3}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Request Type</Form.Label>
                  {location.pathname === "/services/request-supplies"
                    ? options && (
                      <Select
                        defaultValue={{
                          value: "3",
                          label: "Box & Ship Supplies",
                        }}
                        options={options}
                        onChange={(e) => requestTypeChange(e)}
                      />
                    )
                    : options && (
                      <Select
                        defaultValue={
                          location.pathname === "/services/request-pharma-kit"
                            ? { value: "1", label: "PharmaWaste Kit" }
                            : { value: "2", label: "Take Back kit" }
                        }
                        options={options}
                        onChange={(e) => requestTypeChange(e)}
                      />
                    )}
                </Form.Group>
              </Col>

              <Col xs={12} md={6} xl={3} id="disposalAccount">
                <Form.Group className="mb-3">
                  <Form.Label> Account</Form.Label>
                  {disposal_options && (
                    <Select
                      required
                      options={disposal_options}
                      onChange={(e) => disposalAccountChange(e)}
                      // value={disposalAccount}
                      value={
                        disposalAccount
                          ? {
                            value: disposalAccount,
                            label: disposalAccountName,
                          }
                          : {}
                      }
                    />
                  )}

                  {errorDisposalAccountMessage && (
                    <p className="error-validation">
                      {errorDisposalAccountMessage}
                    </p>
                  )}

                  <Form.Control.Feedback type="invalid">
                    Please Select Account.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {location.pathname === "/services/request-supplies" ? (
                <>
                  <Col
                    xs={6}
                    md="auto"
                    className={`${showProductTour ? "request-three" : ""}`}
                  >
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>
                        Supply Type {"  "}
                        <i
                          title={
                            selectedRadio === "kits"
                              ? "Request box, shipping label, and leak-proof liner."
                              : "Request shipping label only."
                          }
                          className="fa-solid fa-circle-info"
                        ></i>
                      </Form.Label>
                      <div className="mt-2">
                        <Form.Check
                          inline
                          type={"radio"}
                          label={"Kits"}
                          name="radioGroup"
                          defaultChecked={true}
                          onChange={(e) => radioChange(e, "kits")}
                        />
                        <Form.Check
                          inline
                          type={"radio"}
                          label={"Labels Only"}
                          name="radioGroup"
                          onChange={(e) => radioChange(e, "Labels")}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4} xl={2}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Quantity </Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="Limit of 10"
                        onChange={(e) => quantityChange(e)}
                        onKeyDown={(evt) =>
                          evt.key === "e" && evt.preventDefault()
                        }
                      />
                      {errorQuantityMessage && (
                        <p className="error-validation">
                          {errorQuantityMessage}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </>
              ) : (
                <Col xs={12} md={6} xl={3}>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Number of kits</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Limit of 100"
                      onChange={(e) => numberOfKitsChange(e)}
                      onKeyDown={(evt) => {
                        evt.key === "e" && evt.preventDefault();
                      }}
                    />
                    {errorFromMessage && (
                      <p className="error-validation">{errorFromMessage}</p>
                    )}
                    <Form.Control.Feedback type="invalid">
                      Please Select Number of Kits
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
              <Col xs={6} md={4} xl={2}>
                <Form.Group className="mt-4">
                  <Button variant="primary" className="px-3" type="submit">
                    Submit
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            borderTop: "2px solid rgba(29, 34, 49, 0.15)",
          }}
        ></div>
        <GridComponent
          kitFilter={true}
          kitFilterChange={onKitFilterChange}
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          globalSearch={globalSearch}
          gridData={gridData}
          setGridData={setGridData}
          gridfilterbutton={false}
          sortChange={onSortChanged}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        ></GridComponent>
      </div>
      <NewNdcSuccessModal
        show={SuccessShow}
        handleClose={SucessClose}
        message1={
          location.pathname === "/services/request-supplies"
            ? "You will receive your supplies in 3-5 business days."
            : "You will receive your kits in 3-5 business days."
        }
      ></NewNdcSuccessModal>
    </Container>
  );
}
export default RequestAPharmaWasteKit;
