import moment from "moment";

export const formatDatePipe = (date: string) => {
  return date && date !== null && date !== undefined ? moment(new Date(date)).format("MM/DD/YYYY") : ''
};

export const visibleColumnNames = (colDefs: any) => {
  let columnName: string[] = [];
  colDefs.forEach((item: any) => {
    if (!item.hide) columnName.push(item.field);
  });
  return columnName;
};

export const durationOptions = [
  {
    name: "Yearly",
    value: 2,
  },
  {
    name: "Quarterly",
    value: 1,
  },
  {
    name: "Monthly",
    value: 0,
  },
];

export const expectedReturnDurationOptions = [
  {
    name: "Last 30 Days",
    value: 0,
  },
  {
    name: "Last 3 Months",
    value: 1,
  },
  {
    name: "Last 12 Months",
    value: 2,
  },
];

export const gridAlignment = {
  LEFT: "leftAligned",
  RIGHT: "rightAligned",
};

export const defaultErrorMessage = "Something went wrong! Please try again later.";

export const ignoreFrontMessageCodes = [401, 403];

export const USER_ROLES = [
  { value: 1, label: "Pharmacy Staff" },
  { value: 2, label: "Management" },
  { value: 3, label: "Admin" },
  { value: 4, label: "Internal Admin" },
];

export const USER_ROLES_WITHOUT_IADMIN = [
  { value: 1, label: "Pharmacy Staff" },
  { value: 2, label: "Management" },
  { value: 3, label: "Admin" },
];


export const defaultNoRecordFound = "No records exist for filtered criteria!";

export const formateIds = (Ids: any) => {

  // const firstNumericValues = Ids.map((item: string) => {
  //           const parts = item.split(':');
  //           const firstNumeric = parts[0].trim();
  //           return firstNumeric;
  //         }).filter((item: string) => item !== null);
  //         return firstNumericValues.toString();
  const idSet: Set<string> = new Set();
  Ids.forEach((item: string) => {
    const parts = item.split(':');
    if (parts.length > 0) {
      const firstNumeric = parts[0].trim();

      if (firstNumeric !== 'Account')
        firstNumeric.split(',').forEach(id => idSet.add(id.trim()));
    }
  });

  return Array.from(idSet).join(',');
};

export const CustomerReturnProgram = [
  {
    label: "All",
    value: null,
  },
  {
    label: "Non-Partnered Wholesaler",
    value: 3,
  },
  {
    label: "Partnered Wholesaler",
    value: 4,
  },
  {
    label: "RCP",
    value: 5,
  },
  {
    label: "Drug Liquidation",
    value: 6,
  }
];

export const ServiceOffering = [
  {
    label: "All",
    value: null,
  },
  {
    label: "Pharmaceutical Returns",
    value: 1,
  },
  {
    label: "RCSCP",
    value: 2,
  },
  {
    label: "PharmaWaste",
    value: 3,
  },
  {
    label: "TakeBack",
    value: 4,
  }
];

export const CollectionOption = [
  {
    label: "All",
    value: null,
  },
  {
    label: "Box And Ship",
    value: 1,
  },
  {
    label: "Pickup",
    value: 2,
  }
];

export const COLUMN_CONFIG_REPORT_NAMES = {
  CountOfScheduleIIAndScheduleIII_V: "CountOfScheduleIIAndScheduleIII_V",
  Form222: "Form222",
  Form41: "Form41",
  EPAComplianceNotification: "EPAComplianceNotification",
  OrderSummary: "OrderSummary",
  DisbursementSummary: "DisbursementSummary",
  DisbursementDetail: "DisbursementDetail",
  InvoiceSummary: "InvoiceSummary",
  InvoiceDetail: "InvoiceDetail",
  SFGSummary: "SFGSummary",
  SFGDetail: "SFGDetail",
  OrderHistory: "OrderHistory",
  PartneredWholesaler: "PartneredWholesaler",
  NonPartneredWholesaler: "NonPartneredWholesaler",
  RCP: "RCP",
  DrugLiquidationOrderHistory: "DrugLiquidationOrderHistory",
  DrugLiquidation: "DrugLiquidation",
  RequestPharmaWasteKit: "RequestPharmaWasteKit",
  RequestBNS: "RequestBNS",
  RequestTakeBackKit: "RequestTakeBackKit",
  RequestOnsiteService: "RequestOnsiteService",
  OrderLineDetail: "OrderLineDetail",
  OverstockSummary: "OverstockSummary",
  TrackShipment: "TrackShipment",
  UpcomingReturn: "UpcomingReturn",
  InventoryReport: "InventoryReport",
  DisposalReturns: "DisposalReturns",
  DestructionManifests: "DestructionManifests",
  ReturnSummary: "ReturnSummary",
  TotalCredit: "TotalCredit",
  UserManagement: "UserManagement",
  InventoriedReturn: "InventoriedReturn",
  InventoriedReturnHistory: "InventoriedReturnHistory"
};

export const APP_BUILD_VERSION = process.env.REACT_APP_NODE_ENV !== "production" ? 'v4.1.12' : '';
export const isScript = /^[^<>;]+$/;
export const internalAdminRole = 4